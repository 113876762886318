<template>
	<div class="divTemplate is-size-5">
		<section>
			<div
				class="parallax fullWindowHeight parallax1"
				src
				style="display: flex"
			>
				<img
					class="cloud"
					src="../../src/assets/img/cloud.svg"
					alt="Clouding network background"
				/>
				<div class="is-centered topMainText" style="margin: auto">
					<div style="display: flex; height: 100vh; margin: 20px">
						<div style="margin: auto">
							<div style="margin: auto; max-width: 70vw; width: 560px">
								<p style="font-size: 5rem">
									<span class="has-text-primary">Hello,</span>
									<br />
									<b class="has-text-secondary">You!</b>
								</p>
								<br />
								<p style="font-size: 1.25rem">
									Check out some of my prototypes! <br />
									All the apps are updated frequently, so don't be surprised if
									you see some changes to the apps while you're browsing around.
									<br /><br />
									Have fun exploring!
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="fullWindowHeight" style="display: flex" id="apps">
				<div style="margin: auto">
					<div
						class="appsDetail columns is-variable is-4 has-text-centered is-desktop is-vcentered"
						style="margin: auto"
					>
						<div class="column">
							<div class="box">
								<img
									style="max-width: 140px; margin: 25px"
									src="../../src/assets/img/yooto.png"
									alt="Planning Poker logo"
								/>
								<p class="title is-4">Yoo.To</p>
								<br />
								<button
									class="button is-primary is-outlined"
									@click="appNav('https://yoo.to', true)"
								>
									<figure
										class="image is-16x16"
										style="margin-right: 16px; opacity: 0.5"
									>
										<img
											src="../../src/assets/img/icons/external-link-alt-solid.svg"
											alt="Instagrms logo"
										/>
									</figure>
									<span>Try</span>
								</button>
								<br />
								<br />
								<div>
									URL Shortener with awesome features like Branding URLs,
									Temporary URLs, and URLs with Notes content.
								</div>
							</div>
						</div>

						<div class="column">
							<div class="box">
								<img
									src="../../src/assets/img/chat.svg"
									alt="Web Chatting logo"
								/>
								<p class="title is-4">Web chatting</p>
								<br />
								<button
									class="button is-outlined is-primary"
									@click="appNav('./chat/index.html')"
								>
									Try
								</button>
								<br />
								<br />
								<div>
									Chatting rooms Prototype. <br />Messages are updated in
									real-time. Mention users in the text. Choose a room and
									nickname to start the fun.
								</div>
							</div>
						</div>

						<div class="column">
							<div class="box">
								<img
									src="../../src/assets/img/pen.svg"
									alt="TS Microblog"
									style="opacity: 0.8; padding: 20px"
								/>
								<p class="title is-4">TS Microblog</p>
								<br />
								<button
									class="button is-outlined is-primary"
									@click="appNav('./blog/index.html')"
								>
									Try
								</button>
								<br />
								<br />
								<div>
									TS Microblog Prototype.<br />
									TypeScripted Vue-Class-Component with MirageJS. <br />
									Elon Musk next acquisition.
								</div>
							</div>
						</div>
					</div>

					<div
						class="appsDetail columns is-variable is-4 has-text-centered is-desktop is-vcentered"
					>
						<div class="column">
							<div class="box">
								<img
									src="../../src/assets/img/dices.svg"
									alt="Shared Dice logo"
								/>
								<p class="title is-4">Shared Dice</p>
								<br />
								<button
									class="button is-outlined is-primary"
									@click="appNav('./dices/index.html')"
								>
									Try
								</button>
								<br />
								<br />
								<div>
									Dices online Prototype. <br />
									3D dice using CSS. The Rolled dice results are shared with
									other players in real-time. Leeeeeroy Jenkins!
								</div>
							</div>
						</div>

						<div class="column">
							<div class="box">
								<img
									src="../../src/assets/img/coffee.svg"
									alt="Planning Poker logo"
								/>
								<p class="title is-4">Planning Poker</p>
								<br />
								<button
									class="button is-outlined is-primary"
									@click="appNav('./poker/index.html')"
								>
									Try
								</button>
								<br />
								<br />
								<div>
									Planning poker Prototype. <br />
									The selected cards are revealed to all members in the room at
									the same time in real-time.
								</div>
							</div>
						</div>

						<div class="column">
							<div class="box">
								<img
									style="background-color: black"
									src="../../src/assets/img/qrDomainBW.svg"
									alt="QR Code Ticket logo"
								/>
								<p class="title is-4">QR Code Ticket</p>
								<br />
								<button
									class="button is-outlined is-primary"
									@click="appNav('./ticket/index.html')"
								>
									Try
								</button>
								<br />
								<br />
								<div>
									QR Code reader using device's camera. Create tickets with QR
									Code, and scan them to mark them as used. Doubled scans are
									highlighted.
								</div>
							</div>
						</div>

						<!-- <div class="column">
							<div class="box">
								<img
									src="../../src/assets/img/instagrams.svg"
									alt="Instagrms logo"
								/>
								<p class="title is-4">Instagrams</p>
								<br />
								<button class="button" disabled>Soon</button>
								<br />
								<br />
								<div>
									Publish a breakfast, lunch, dinner or a snack picture and get
									a rate and comments about it
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="parallax fullWindowHeight parallax1 column">
				<br />
				<br />
				<div class="columns is-variable is-4">
					<div class="column is-two-thirds">
						<p class="title is-5 sectionTitle" id="bio">Bio</p>
						<div class="bioContent">
							I am enthusiastic about lifelong learning, technology, travel, and
							dance. I have been working for two decades on IT and mostly in
							software development in various software projects, from
							local-server desktops to modern SaaS programs.
							<br />
							<br />
							Since 2017, I have been focusing on cloud-based projects with
							full-stack roles, with a particular focus on front-end development
							using VueJs (VueRouter, Vuex), and back-end on AWS technologies,
							such as API Gateway, Lambdas, EC2, S3, Cognito, IAM, CloudWatch,
							CloudFront, CloudFormation, WebSockets, Route53, DynamoDB, ACM,
							and IAM.
						</div>
					</div>
					<div class="column contactMe" id="contactMe">
						<p class="title is-5 sectionTitle">Contact me</p>
						<div class="columns">
							<img
								src="../../src/assets/img/icons/location-outline.svg"
								class="icon is-medium"
								alt="location-outline icon"
							/>
							<span>Cork, Ireland</span>
						</div>
						<br />
						<div class="columns">
							<img
								src="../../src/assets/img/icons/mail-outline.svg"
								class="icon is-medium"
								alt="mail-outline icon"
							/>
							<span>
								<a href="mailto:aesmerio@gmail.com" target="_top"
									>aesmerio@gmail.com</a
								>
							</span>
						</div>
						<br />
						<div class="columns">
							<img
								src="../../src/assets/img/icons/logo-linkedin.svg"
								class="icon is-medium"
								alt="logo-linkedin icon"
							/>
							<span>
								<a
									href="https://www.linkedin.com/in/airton-esmerio"
									target="_top"
									>linkedin.com/in/airton-esmerio</a
								>
							</span>
						</div>
						<br />
						<div class="columns">
							<img
								src="../../src/assets/img/icons/github-brands.svg"
								class="icon is-medium"
								alt="logo-linkedin icon"
							/>
							<span>
								<a href="https://github.com/AEsmerio" target="_top"
									>github.com/AEsmerio</a
								>
							</span>
						</div>
						<br />
						<!-- <div class="columns">
							<img
								src="../../src/assets/img/icons/bookmark-outline.svg"
								class="icon is-medium"
								alt="bookmark-outline icon"
							/>
							<span>
								<a href="https://aesmerio.eu" target="_top">aesmerio.eu</a>
							</span>
						</div> -->
					</div>
				</div>
				<br />
				<br />
				<br />
				<ContactForm />
			</div>
			<!-- <div class="appVersion" v-text="$store.getters.appVersion"></div> -->
		</section>
	</div>
</template>

<script>
export default {
	name: "home",
	components: {
		ContactForm: () => import("@/../../../components/ContactForm.vue"),
	},
	methods: {
		appNav(url, newTab) {
			if (newTab) window.open(url, "_blank")
			else window.location.href = url
		},
	},
}
</script>  

<style scoped>
.bioContent {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	text-align: justify;
	text-justify: inter-word;
}
.icon {
	margin-right: 10px;
}

.appVersion {
	text-align: center;
	font-size: 1rem;
}

.contactMe ion-icon {
	margin-right: 10px;
}
.sectionTitle {
	padding: 40px;
	text-align: center;
}

.helloYou {
	font-size: 5rem;
}

.cloud {
	position: absolute;
	height: 20vmin;
	max-height: 200px;
	top: 18%;
	right: 10%;
}

.lines {
	/* border: 1px solid red; */
	position: absolute;
	opacity: 0.02;
}

.parallax1 {
	background-image: linear-gradient(
			rgba(255, 255, 255, 0.98),
			rgba(255, 255, 255, 0.98)
		),
		url("../../src/assets/img/lines.svg");
}

.appsDetail img {
	max-height: 100px;
}
</style>
